.warnUser {
  align-items: unset !important;
  background-color: #fef0c0;
  padding: 0.5em 0.7em;
  margin-top: 1em;
  border-radius: 8px;
  margin-bottom: -1em;
  display: flex;
  align-items: baseline;
}

.errorUser {
  align-items: unset !important;
  background-color: #ffd6d6;
  padding: 0.5em 0.7em;
  border-radius: 8px;
  display: flex;
  align-items: baseline;
}

.successUser {
  align-items: unset !important;
  background-color: #cefcd8;
  padding: 0.5em 0.7em;
  border-radius: 8px;
  display: flex;
  align-items: baseline;
}

.warn {
  padding: 0em;
  margin: 0.5em 0em 0.4em;
  text-align: center;
}

.iconWarn {
  margin-right: "0.6em";
  min-height: "1.2em";
  padding-bottom: "0.2em";
  padding-top: "0.1em";
}
