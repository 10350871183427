.AppStyle {
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 10px 5pc rgb(6 114 139 / 35%);
  width: 100%;

  .colMainContent {
    // set width using media queries bassed on bootstrap breakpoints
    // width: 100%;
    // padding: 16px 0;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .imgFundo {
    border-radius: 10px;
  }

  .imgLogo {
    margin: 0 auto;
    width: 200px;

    @media (min-width: 576px) {
      width: 300px;
    }

    @media (min-width: 768px) {
      width: 200px;
    }

    @media (min-width: 992px) {
      width: 250px;
    }
  }

  .formLogin {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 560px;
    align-content: center;

    @media (max-width: 1600px) {
      max-height: 540px;
    }

    @media (max-width: 1399px) {
      max-height: 516px;
    }

    @media (max-width: 1199px) {
      max-height: 503px;
    }

    @media (max-width: 991px) {
      max-height: 460px;
    }

    @media (max-width: 767px) {
      max-height: none;
    }
  }

  .password {
    background-color: #ECEAEA !important;
    border-style: solid !important;
    border-radius: 8px !important;
    box-sizing: border-box;
    border: 2px solid #eceaea;
    transition: 0.5s;
    outline: none;
    padding: 0.375rem 0.75rem;
    display: flex;
    justify-content: space-between;
  }

  .password:focus-within {
    border: 2px solid rgb(135, 184, 254);
  }

  .register {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  .password:focus {
    border: 3px solid #00b3e583;
  }

  .imgLoading {
    filter: blur(10px);
    clip-path: inset(0);
  }

  .imgLoaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }
}

.mainContainer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    max-width: 760px !important;
    ;
  }

  @media (min-width: 992px) {
    max-width: 830px !important;
    ;
  }

  @media (min-width: 1200px) {
    max-width: 850px !important;
    ;
  }

  @media (min-width: 1400px) {
    max-width: 900px !important;
  }

  @media (min-width: 1600px) {
    max-width: 950px !important;
  }
}

.colForm {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    display: block;
  }
}