/* @import url('%PUBLIC_URL%/fonts/fonts/stylesheet.css'); */

@import url('assets/fonts/stylesheet.css');

html {
	overflow-x: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
	height: 100%;
	align-items: center;
	display: flex;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}


input {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	background-color: #ECEAEA !important;
	border-radius: 8px !important;
	/* width: 100%;
	padding: 12px 20px;
	margin: 8px 0; */
	box-sizing: border-box;
	border: 0px solid #ccc;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
}

input:checked {
	background-color: #005CA8 !important;
}

/* input:focus {
	border: 3px solid rgb(135, 184, 254);
	border: 3px solid #00b3e583;
  } */

textarea:focus,
input:focus {
	outline: none;
}

*:focus {
	outline: none;
}

button {
	border: unset;
	background-color: unset;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
}

@import url('https://fonts.googleapis.com/css2?family=Manjari&display=swap');

body {
	display: flex;
	justify-content: center;
	font-family: 'Euclid Circular A', sans-serif !important;
	color: #000000;
	background-color: #ffffff;
	width: 100%;
	box-sizing: border-box;
}

a {
	text-decoration: none !important;
}

#root {
	width: 100%;
}

::-webkit-scrollbar {
	width: 8px;
	max-height: 1em;
}

::-webkit-scrollbar-track {
	margin: 2.2em;
	background: unset;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: #b6b7b9;
}

::-webkit-scrollbar-thumb:hover {
	background: #888;
}

.imgLoading {
	filter: blur(10px);
	clip-path: inset(0);
}

.imgLoaded {
	filter: blur(0px);
	transition: filter 0.2s linear;
}