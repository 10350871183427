.field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
  justify-content: space-around;
}


.password {
  background-color: #ECEAEA !important;
  border-style: solid !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: 2px solid #eceaea;
  transition: 0.5s;
  outline: none;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-between;
}

.password:focus-within {
  border: 2px solid rgb(135, 184, 254);
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}


.warnPassword {
  align-items: unset !important;
  background-color: #ffd6d6;
}

.warnKey {
  padding: 0.5em 0.7em;
  margin-top: 0.8em;
  border-radius: 8px;
  margin-bottom: -1em;
  display: flex;
  align-items: baseline;
}

.warnError {
  white-space: pre-wrap;
  padding-top: 0.1em;
}

input::-ms-reveal {
  display: none;
}

.register {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  text-align: center;
  margin-top: 1em !important;
}

.fieldError {
  border-color: #f94646 !important;
}

.fieldSuccess {
  border-color: #00000000;
}


.title {
  padding: 1em 3em 0em;
  text-align: center;
  font-weight: 600;
  font-size: 1.25em;
}

.submitButton {
  align-self: center;
  background-color: #005CA8;
  border-radius: 9px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 0em;
  width: 80%;
  margin-top: 1em;
  box-shadow: 2px 4px 4px #0000009F;
  margin-bottom: 1em;
  width: 10em;

  &:active {
    background-color: #014881;
  }

  &:disabled {
    background-color: #a3d5ff;
    cursor: not-allowed;
    box-shadow: 2px 2px 4px #0000009F inset;
  }
}

.ConfigStyle {

  .title {
    padding: 1em 3em 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 1.1em;
  }

  .description {
    display: block;
    padding-top: 0.5em;
  }

  .text {
    margin-left: 0.8em;
    padding: 0 0 1em;
    list-style: auto;
  }

  .qrCode {
    display: flex;
    justify-content: center;
    margin: 0;
  }

}

.spinner {
  width: 12px;
  height: 12px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.imgUser {
  height: 170px;
  width: 170px;
}

.return {
  padding: 0em 0em 0em 1.5em;

  @media screen and (max-width: 767px) {
    padding-left: 2.5em !important;
  }
}

.btnSession {
  background-color: #ECEAEA;
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  width: 18.75rem;
  min-height: 2.5rem;
  transition: background-color 0.2s;
  cursor: pointer;
  height: 100%;
  white-space: nowrap;
}

.btnSessionEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22ch;
}

.btnSession a { 
  color: var(--bs-body-color);
  width: 100%;
}

.btnSession:hover {
  background-color: #cacaca;
}

.name {
  font-weight: bold;
}

.email {
  font-size: 0.875rem;  
}

.btnSession p {
  font-size: 0.75rem;
}

.btnSession img {
  border-radius: 50%;
  width: 1.875rem;
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0 1rem 0 0;
}

.btnSession svg {
  color: var(--bs-body-color);
  width: 1.875rem;
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0 1rem 0 0;
}